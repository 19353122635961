// socket.js
import io from "socket.io-client";

let socket = null;
console.log(process.env.REACT_APP_BACKEND_API_URL, "process.env.REACT_APP_BACKEND_API_URL");
export const initiateSocket = (token) => {
  socket = io.connect(process.env.REACT_APP_BACKEND_API_URL, {
    extraHeaders: {
      "Authorization": token,
    },
  });
}

export const getSocket = () => socket;
