import React from 'react'

function Settings() {
  return (
    <div className="flex justify-center  h-[1000px]">
     <p className='text-black text-center mt-10 text-[30px]'>Coming Soon...</p>
      </div>
  )
}

export default Settings